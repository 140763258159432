import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom/client"
import { useLocation, Route, Routes, Navigate, createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom"
import { createBrowserHistory } from "history"
import Cookies from "js-cookie"
import "./styles/shared/index.scss"
import { DateTime} from "luxon"

import Login from "./components/wfm-2.0/login"
import ForgotPassword from "./components/wfm-2.0/forgot-password"
import ResetPassword from "./components/wfm-2.0/reset-password"

import Activate from "./pages/activate-account"
import { Provider } from "react-redux"
import configureStore from "./redux"

import { Auth0Provider, useAuth0 } from "@auth0/auth0-react"

// These pages are for admin dashboard
import GlobalSearchPage from "./pages/admin/global-search-page"
import CompanyListingPage from "./pages/admin/company-listing-page"
import CompanyDetailPage from "./pages/admin/company-detail-page"
import LocationListingPage from "./pages/admin/location-listing-page"
import LocationDetailPage from "./pages/admin/location-detail-page"
import EmployeeListingPage from "./pages/admin/employee-listing-page"
import EmployeeDetailPage from "./pages/admin/employee-detail-page"
import InventoryCompanyListingPage from "./pages/admin/inventory-company-listing-page"
import InventoryLocationListingPage from "./pages/admin/inventory-location-listing-page"
import DeviceDetailPage from "./pages/admin/device-detail-page"
import JobDetailPage from "./pages/admin/job-detail-page"
import AdminSettingsPage from "./pages/admin/settings-page"
import ManagerDetailPage from "./pages/admin/manager-detail-page"
import CSVUploadPage from "./pages/admin/csv-upload-page"
import ReportingPage from "./pages/admin/reporting-page"
import InventoryDeviceListingPage from "./pages/admin/inventory-device-listing-page"
import InventoryDeviceDetailPage from "./pages/admin/inventory-device-detail-page"
import UtilitiesPage from "./pages/admin/utilities-page"
import SettingsPackageDetailPage from "./pages/admin/settings-package-detail-page"

import { syncHistoryWithStore } from "react-router-redux"

import $ from "jquery"

import "react-select/dist/react-select.css"


import Layout from "./layout/Layout"

import { initialRedirectTo, setLoggedIn } from "./redux/actions/user"

// Initialize Sentry if the SENTRY_DSN config var is set.

const store = configureStore()
window.testValue = "TRUE"
window.store = store
window.$ = $
window.Cookies = Cookies

// polyfill for stupid IE
if (!("remove" in Element.prototype)) {
  Element.prototype.remove = function () {
    if (this.parentNode) {
      this.parentNode.removeChild(this)
    }
  }
}

export const history = syncHistoryWithStore(createBrowserHistory(), store)

if (window.KINETIC_DASHBOARD_AUTH && !window.KINETIC_DASHBOARD_AUTH.message) {
  // This page load is the completion of a SAML auth flow, so redirect
  // accordingly.

  const { email, relayState, ...data } = window.KINETIC_DASHBOARD_AUTH

  setLoggedIn(email, data, store.dispatch)
  initialRedirectTo(relayState)
}

function routes() {
  const location = useLocation()
  if (location.pathname.includes("/select-location")) {
    return <Navigate to="/" />
  }

  
  return (
  <Routes>
       <Route path="/" index Component={GlobalSearchPage} /> 
       <Route path="/forgot-password" exact Component={<ForgotPassword />} />
      <Route path="/login" exact Component={Login} />
      <Route path="/activate" Component={Activate} />
      <Route path="/reset-password" exact Component={ResetPassword} />
      <Route path="/email-reports" exact Component={ReportingPage} />
      <Route path="/settings" exact Component={AdminSettingsPage} />

      <Route path="/utilities" exact Component={UtilitiesPage} />
      <Route
        path="/utilities/settings-packages/add"
        exact
        Component={SettingsPackageDetailPage}
      />
      <Route
        path="/utilities/settings-packages/:settingspackageuid"
        exact
        Component={SettingsPackageDetailPage}
      />
      <Route
        path="/utilities/settings-packages/:settingspackageuid/edit"
        exact
        Component={SettingsPackageDetailPage}
      />

      <Route
        path="/inventory"
        exact
        Component={InventoryCompanyListingPage}
      />
      <Route
        path="/inventory/:companyid"
        exact
        Component={InventoryLocationListingPage}
      />
      <Route
        path="/inventory/devices/:deviceuid"
        exact
        Component={InventoryDeviceDetailPage}
      />

        <Route
        path="/inventory/location/:locationid"
        exact
        Component={InventoryDeviceListingPage}
      />
      <Route
        path="/inventory/location/:locationid/devices/:deviceid"
        exact
        Component={DeviceDetailPage}
         />

      <Route
        path="/inventory/location/:locationid/devices/:deviceid/edit"
        exact
        Component={DeviceDetailPage}
      />

      <Route path="/companies" exact Component={CompanyListingPage} />
      <Route path="/companies/add" exact Component={CompanyDetailPage} />
      <Route
        path="/companies/:companyid"
        exact
        Component={LocationListingPage}
      />
      <Route
        path="/companies/:companyid/edit"
        exact
        Component={CompanyDetailPage}
      />
      <Route
        path="/companies/:companyid/devices/csv"
        exact
        Component={CSVUploadPage}
      />
      <Route
        path="/companies/:companyid/managers/add"
        exact
        Component={ManagerDetailPage}
      />
      <Route
        path="/companies/:companyid/managers/csv"
        exact
        Component={CSVUploadPage}
      />
      <Route
        path="/companies/:companyid/managers/:managerid"
        exact
        Component={ManagerDetailPage}
      />
      <Route
        path="/companies/:companyid/managers/:managerid/edit"
        exact
        Component={ManagerDetailPage}
      />

      <Route
        path="/locations/add"
        exact
        Component={LocationDetailPage}
      />
      <Route path="/locations/csv" exact Component={CSVUploadPage} />
      <Route
        path="/locations/:locationid"
        exact
        Component={EmployeeListingPage}
      />
      <Route
        path="/locations/:locationid/edit"
        exact
        Component={LocationDetailPage}
      />
      <Route
        path="/locations/:locationid/employees/add"
        exact
        Component={EmployeeDetailPage}
      />
      <Route
        path="/locations/:locationid/employees/csv"
        exact
        Component={CSVUploadPage}
      />
      <Route
        path="/locations/:locationid/employees/:employeeid"
        exact
        Component={EmployeeDetailPage}
      />
      <Route
        path="/locations/:locationid/employees/:employeeid/edit"
        exact
        Component={EmployeeDetailPage}
      />
      <Route
        path="/locations/:locationid/devices/:deviceid"
        exact
        Component={DeviceDetailPage}
      />
      <Route
        path="/locations/:locationid/devices/:deviceid/edit"
        exact
        Component={DeviceDetailPage}
      />
      <Route
        path="/locations/:locationid/jobs/add"
        exact
        Component={JobDetailPage}
      />
      <Route
        path="/locations/:locationid/jobs/:jobid"
        exact
        Component={JobDetailPage}
      />
      <Route
        path="/locations/:locationid/jobs/:jobid/edit"
        exact
        Component={JobDetailPage}
      />
      <Route
        path="/locations/:locationid/managers/:managerid"
        exact
        Component={ManagerDetailPage}
      />
      <Route
        path="/locations/:locationid/managers/:managerid/edit"
        exact
        Component={ManagerDetailPage}
      />
      <Route Component={Login} /> 
    </Routes> 
  )

}

function App() {
  return (
     <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN.slice(0, -1)}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
     >
       <Provider store={store}>
           <AuthWrapper>
            <Layout>
                {routes()}
            </Layout>
        </AuthWrapper>
       </Provider>
     </Auth0Provider>
  )
}

function AuthWrapper(props) {
  const auth = useAuth0()
  const [ready, readySet] = React.useState(false)

  useEffect(() => {
    async function init() {
      if (auth.isLoading) return null
      if (!auth.isAuthenticated) {
        auth.loginWithRedirect({
          appState: { returnTo: window.location.pathname },
         })
        return null
      } else {
        fetchToken()
      }
    }
    
    async function fetchToken() {
      const token = await auth.getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE
      })
       localStorage.setItem("adminToken", token)
       readySet(true)
    }

    init()
  }, [auth.isAuthenticated, auth.isLoading])

  if (ready) return props.children
  return null
}

const router = createBrowserRouter(
  createRoutesFromElements(<Route path="/*" element={<App />} />)
)

ReactDOM.createRoot(document.getElementById("root")).render(
    <RouterProvider router={router} />
)

window.$ = $

// add jQuery function
window.$.extend(window.$.fn, {
  // Name of our method & one argument (the parent selector)
  within: function (pSelector) {
    // Returns a subset of items using jQuery.filter
    return this.filter(function () {
      // Return truthy/falsey based on presence in parent
      return window.$(this).closest(pSelector).length
    })
  },
})


export function timeagoFormat(date) {
  return DateTime.fromISO(date).toRelative()
}